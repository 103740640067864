import gql from 'graphql-tag'
import { QueryResult } from 'react-apollo'

export type GetVideoUrl = QueryResult<{
  getUrlRes: {
    signedUrl: string
  }
}>

export const getVideoUrlQuery = (key: string, download = 0) => ({
  query: gql`
    query($key: String!, $download: Number) {
      getUrlRes(key: $key, download: $download)
        @rest(type: "VideoUrl", path: "/getVideoUrl?key=:key&download=:download", endpoint: "query") {
        signedUrl
      }
    }
  `,
  variables: { key, download },
})
