// reducers.ts
import { Reducer } from 'redux'
import { RecordModalAction } from '../actions/recordModal.actions'

interface ModalState {
  isModalOpen: boolean
  videoId: string | null
  isRecording: boolean
  version: number | null
  refetch?: () => void | null
  recordType?: string | null
  lang?: string | null
}

const initialState: ModalState = {
  isModalOpen: false,
  videoId: null,
  isRecording: false,
  version: null,
  refetch: null,
  recordType: null,
  lang: null,
}

export const modalReducer: Reducer<ModalState, RecordModalAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RECORD_MODAL':
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen,
        videoId: action.payload.videoId,
        isRecording: action.payload.isRecording,
        version: action.payload.version,
        refetch: action.payload.refetch,
        recordType: action.payload.recordType,
        lang: action.payload.lang,
      }
    default:
      return state
  }
}
