// reducers.ts
import { Reducer } from 'redux'
import { UploadModalAction } from '../actions'

interface ModalState {
  videoId: string | null
  isUploading: boolean
  file?: any
  isModalOpen: boolean
  version: number | null
  refetch?: () => void | null
  uploadType?: string | null
  lang?: string | null
}

const initialState: ModalState = {
  videoId: null,
  isUploading: false,
  file: null,
  isModalOpen: false,
  version: null,
  refetch: null,
  uploadType: null,
  lang: null,
}

export const uploadModalReducer: Reducer<ModalState, UploadModalAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_UPLOAD_MODAL':
      return {
        ...state,
        videoId: action.payload.videoId,
        isUploading: action.payload.isUploading,
        file: action.payload.file,
        isModalOpen: action.payload.isModalOpen,
        version: action.payload.version,
        refetch: action.payload.refetch,
        uploadType: action.payload.uploadType,
        lang: action.payload.lang,
      }
    default:
      return state
  }
}
