// actions.ts
import { Action } from 'redux'

export interface RecordModalAction extends Action {
  type: 'SET_RECORD_MODAL'
  payload: {
    videoId?: string
    isRecording?: boolean
    isModalOpen?: boolean
    version?: number
    refetch?: any
    recordType?: string
    lang?: string
  }
}

export type ModalActionTypes = RecordModalAction

export const setRecordModal = ({
  isModalOpen,
  videoId,
  isRecording,
  version,
  refetch,
  recordType,
  lang,
}): RecordModalAction => {
  return {
    type: 'SET_RECORD_MODAL',
    payload: {
      videoId,
      isRecording,
      isModalOpen,
      version,
      refetch,
      recordType,
      lang,
    },
  }
}
