import React, { useEffect, useState } from 'react'
import { CircularProgress, LinearProgress, Dialog, DialogContent } from '@material-ui/core'
import { RecordModal, ReduxState } from 'src/store/reducers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setRecordModal } from 'src/store/actions'
import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc'
import { getTranslation, Translate } from 'src/translation'
import { videoCommandVariables, VIDEO_COMMAND_MUTATION } from 'src/gql/videoCommand'
import { useMutation } from 'react-apollo'
import { uploadFiles } from 'src/auth/cognito/organizationApi'
import history from 'src/history'
import { sleep } from 'src/utils/sleepFunction'
import { ErrorDialog } from '../ErrorDialog'

type Props = {
  modalReducer: RecordModal
  setRecordModal: any
  translate: Translate
  tubeColor: string
}

const RecorderDialog = (props: Props) => {
  const { modalReducer, setRecordModal, translate, tubeColor } = props
  const [error, setError] = useState('')

  const [blob, setVideoUrlBlob] = useState<Blob | null>(null)
  const [uploadProcessMultiple, setUploadProcessMultiple] = React.useState(null)
  const [updateVersion] = useMutation(VIDEO_COMMAND_MUTATION, {
    onError: error => {
      setError(error.message)
    },
  })

  const [updateGeneralCommand] = useMutation(VIDEO_COMMAND_MUTATION, {
    onError: error => {
      setError(error.message)
    },
    onCompleted: async data => {
      setRecordModal({
        isModalOpen: true,
        videoId: modalReducer.videoId,
        isRecording: false,
        version: modalReducer.version,
        refetch: modalReducer.refetch,
        recordType: modalReducer.recordType,
        lang: modalReducer.lang,
      })
    },
  })

  // start all the screen record functions
  const startScreenRecording = async () => {
    try {
      const mediaDevices = navigator.mediaDevices

      const displayStream: MediaStream = await (mediaDevices as any).getDisplayMedia({
        video: {
          displaySurface: 'monitor',
          logicalSurface: true,
          cursor: 'always',
        },
        audio: true,
      })

      const [videoTrack] = displayStream.getVideoTracks()

      const audioStream = await mediaDevices.getUserMedia({ audio: true })

      const [microphoneAudioTrack] = audioStream.getAudioTracks()

      const stream = new MediaStream([videoTrack, microphoneAudioTrack])

      const recorderData: RecordRTC = new RecordRTCPromisesHandler(stream, {
        type: 'video',
        mimeType: 'video/webm',
      })

      await recorderData.startRecording()

      displayStream.getVideoTracks()[0].onended = () => {
        setRecordModal({
          isModalOpen: true,
          videoId: modalReducer.videoId,
          isRecording: false,
          version: modalReducer.version,
          refetch: modalReducer.refetch,
          recordType: modalReducer.recordType,
          lang: modalReducer.lang,
        })

        stopRecording({ recorder: recorderData, stream })
      }
    } catch (error) {
      console.error('Error starting recording', { error })

      setRecordModal({
        isModalOpen: false,
        videoId: null,
        isRecording: false,
        version: null,
        refetch: null,
        recordType: null,
        lang: null,
      })
    }
  }

  const stopRecording = async ({ recorder, stream }) => {
    await recorder.stopRecording()
    const blob = await recorder.getBlob()

    setVideoUrlBlob(blob)

    await recorder.destroy()

    stream.getAudioTracks().forEach(track => track.stop())

    stream.getVideoTracks().forEach(track => track.stop())

    await (stream as any).stop()
  }

  const onUploadProgressMultiple = (data: ProgressEvent, id: string) => {
    setUploadProcessMultiple({
      ...uploadProcessMultiple,
      [id]: (data.loaded / data.total) * 100,
    })
  }

  const uploadFile = async () => {
    const todayDate = new Date().toISOString().slice(0, 10)

    const fileName = `${todayDate}-${modalReducer.videoId}`

    // Can be used when uploading to server.
    // we need to upload "File" --- not "Blob"
    const fileObject = new File([blob], fileName, {
      type: 'video/webm',
    })

    uploadFiles(
      [
        {
          id: 'source',
          file: fileObject,
        },
      ],
      onUploadProgressMultiple
    ).then(async res => {
      if (modalReducer.recordType === 'new-version') {
        await updateVersion(
          videoCommandVariables({
            type: 'createMajorVersion',
            payload: {
              videoId: modalReducer.videoId,
              language: modalReducer.lang,
              reuseVideo: false,
              reuseRenderDefinition: false,
              keepTranslations: [],
            },
          })
        )
      }
      await updateGeneralCommand(
        videoCommandVariables({
          type: 'updateGeneral',
          payload: {
            videoId: modalReducer.videoId,
            source: res.source,
            version: modalReducer.recordType === 'new-version' ? modalReducer.version + 1 : modalReducer.version,
            uploadOptions: {
              autoTranscribe: true,
              autoCopyToManuscript: true,
              autoCopyManuscriptToDescription: true,
            },
          },
        })
      )
      setUploadProcessMultiple(null)
      setVideoUrlBlob(null)

      // putting 5 seconds of sleep so that video has some time to get indexed before refetching
      await sleep(5000)
      await modalReducer?.refetch()
      if (window.location.pathname === `/video/${modalReducer.videoId}/${modalReducer.version}/${modalReducer.lang}`) {
        history.push(`/video/${modalReducer.videoId}/${modalReducer.version}/${modalReducer.lang}`)
      } else {
        history.push(`/video/${modalReducer.videoId}`)
      }
      await modalReducer?.refetch()

      setRecordModal({
        isModalOpen: false,
        videoId: null,
        isRecording: false,
        version: null,
        refetch: null,
        recordType: null,
        lang: null,
      })
    })
  }
  const requestPermission = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

    await navigator.mediaDevices.enumerateDevices()
  }

  useEffect(() => {
    if (modalReducer.isRecording && !blob) {
      requestPermission().then(() => {
        startScreenRecording()
      })
    }
  }, [modalReducer.isRecording])

  useEffect(() => {
    if (blob) {
      uploadFile()
    }
  }, [blob])

  return (
    <>
      <Dialog open={modalReducer.isModalOpen}>
        <DialogContent style={{ width: '400px', textAlign: 'center' }}>
          <CircularProgress
            value={uploadProcessMultiple?.source}
            style={{
              marginBottom: '16px',
              transition: 'transform 0.3s ease',
              color: tubeColor,
            }}
          />
          {uploadProcessMultiple?.source && uploadProcessMultiple?.source !== 100 && (
            <LinearProgress
              variant="determinate"
              value={uploadProcessMultiple?.source}
              style={{ width: '100%', height: '8px', borderRadius: '4px', marginBottom: '16px', color: tubeColor }}
            />
          )}
          <h2
            style={{
              textAlign: 'center',
              fontSize: '24px',
              transition: 'opacity 0.3s ease',
            }}
          >
            {uploadProcessMultiple && uploadProcessMultiple?.source !== 100
              ? translate('uploading')
              : translate('hold-on-magic-happens')}
          </h2>
          {uploadProcessMultiple?.source && (
            <p
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
                transition: 'opacity 0.3s ease',
              }}
            >
              {`${uploadProcessMultiple?.source.toFixed(0)} %`}
            </p>
          )}
        </DialogContent>
      </Dialog>
      <ErrorDialog open={Boolean(error)} message={error} onClose={() => setError('')} />
    </>
  )
}

const mapStateToProps = (state: ReduxState, ownProps) => {
  return {
    ...ownProps,
    modalReducer: state.modalReducer,
    tubeColor: state.auth.tube.color,
    translate: getTranslation(state.translation.lang),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setRecordModal,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecorderDialog)
