import * as React from 'react'
import { Query } from 'react-apollo'
import { getVideoUrlQuery, GetVideoUrl } from '../../gql/getVideoUrl'

export class QueryS3Url extends React.PureComponent {
  props: {
    children: any
    download?: number
    source: string
  }

  render() {
    return !this.props.source ? (
      this.props.children({ url: null })
    ) : this.props.source.startsWith('http') ? (
      this.props.children({ url: this.props.source })
    ) : (
      <Query {...getVideoUrlQuery(this.props.source, this.props.download)}>
        {({ loading, error, data }: GetVideoUrl) => {
          return this.props.children({
            loading,
            error,
            url: data && data.getUrlRes && data.getUrlRes.signedUrl ? data.getUrlRes.signedUrl : null,
          })
        }}
      </Query>
    )
  }
}
