import React, { useEffect, useState } from 'react'
import { CircularProgress, LinearProgress, Dialog, DialogContent } from '@material-ui/core'
import { ReduxState, UploadModal } from 'src/store/reducers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setUploadModal } from 'src/store/actions'
import { getTranslation, Translate } from 'src/translation'
import { videoCommandVariables, VIDEO_COMMAND_MUTATION } from 'src/gql/videoCommand'
import { useMutation } from 'react-apollo'
import { uploadFiles } from 'src/auth/cognito/organizationApi'
import history from 'src/history'
import { sleep } from 'src/utils/sleepFunction'
import { ErrorDialog } from '../ErrorDialog'

type Props = {
  uploadModalReducer: UploadModal
  setUploadModal: any
  translate: Translate
  tubeColor: string
}

const UploadDialog = (props: Props) => {
  const { uploadModalReducer, setUploadModal, translate, tubeColor } = props
  const [error, setError] = useState('')

  const [uploadProcessMultiple, setUploadProcessMultiple] = React.useState(null)
  const [updateVersion] = useMutation(VIDEO_COMMAND_MUTATION, {
    onError: error => {
      setError(error.message)
    },
  })

  const [updateGeneralCommand] = useMutation(VIDEO_COMMAND_MUTATION, {
    onError: error => {
      setError(error.message)
    },
    onCompleted: async data => {
      setUploadModal({
        isModalOpen: true,
        file: uploadModalReducer.file,
        videoId: uploadModalReducer.videoId,
        isUploading: true,
        version: uploadModalReducer.version,
        refetch: uploadModalReducer.refetch,
        uploadType: uploadModalReducer.uploadType,
        lang: uploadModalReducer.lang,
      })
    },
  })

  const onUploadProgressMultiple = (data: ProgressEvent, id: string) => {
    setUploadProcessMultiple({
      ...uploadProcessMultiple,
      [id]: (data.loaded / data.total) * 100,
    })
  }

  const uploadFile = async () => {
    uploadFiles(
      [
        {
          id: 'source',
          file: uploadModalReducer.file,
        },
      ],
      onUploadProgressMultiple
    ).then(async res => {
      if (uploadModalReducer.uploadType === 'new-version') {
        await updateVersion(
          videoCommandVariables({
            type: 'createMajorVersion',
            payload: {
              videoId: uploadModalReducer.videoId,
              language: uploadModalReducer.lang,
              reuseVideo: false,
              reuseRenderDefinition: false,
              keepTranslations: [],
            },
          })
        )
      }
      await updateGeneralCommand(
        videoCommandVariables({
          type: 'updateGeneral',
          payload: {
            videoId: uploadModalReducer.videoId,
            source: res.source,
            version:
              uploadModalReducer.uploadType === 'new-version'
                ? uploadModalReducer.version + 1
                : uploadModalReducer.version,
            uploadOptions: {
              autoTranscribe: true,
              autoCopyToManuscript: true,
              autoCopyManuscriptToDescription: true,
            },
          },
        })
      )

      await sleep(5000)
      await uploadModalReducer?.refetch()

      if (
        window.location.pathname ===
        `/video/${uploadModalReducer.videoId}/${uploadModalReducer.version}/${uploadModalReducer.lang}`
      ) {
        history.push(`/video/${uploadModalReducer.videoId}/${uploadModalReducer.version}/${uploadModalReducer.lang}`)
      } else {
        history.push(`/video/${uploadModalReducer.videoId}`)
      }
      await uploadModalReducer?.refetch()

      setUploadModal({
        isModalOpen: false,
        file: null,
        videoId: null,
        isUploading: false,
        version: null,
        refetch: null,
        uploadType: null,
        lang: null,
      })
    })
  }

  useEffect(() => {
    if (uploadModalReducer.isUploading) {
      uploadFile()
    }
  }, [uploadModalReducer.isUploading])

  return (
    <>
      <ErrorDialog open={Boolean(error)} message={error} onClose={() => setError('')} />

      <Dialog open={uploadModalReducer.isModalOpen}>
        <DialogContent style={{ width: '400px', textAlign: 'center' }}>
          <CircularProgress
            value={uploadProcessMultiple?.source}
            style={{
              marginBottom: '16px',
              transition: 'transform 0.3s ease',
              color: tubeColor,
            }}
          />{' '}
          {uploadProcessMultiple?.source && uploadProcessMultiple?.source !== 100 && (
            <LinearProgress
              variant="determinate"
              value={uploadProcessMultiple?.source}
              style={{ width: '100%', height: '8px', borderRadius: '4px', marginBottom: '16px', color: tubeColor }}
            />
          )}
          <h2
            style={{
              textAlign: 'center',
              fontSize: '24px',
              transition: 'opacity 0.3s ease',
            }}
          >
            {uploadProcessMultiple && uploadProcessMultiple?.source !== 100
              ? translate('uploading')
              : translate('hold-on-magic-happens')}
          </h2>
          {uploadProcessMultiple?.source && (
            <p
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
                transition: 'opacity 0.3s ease',
              }}
            >
              {`${uploadProcessMultiple?.source.toFixed(0)} %`}
            </p>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: ReduxState, ownProps) => {
  return {
    ...ownProps,
    uploadModalReducer: state.uploadModalReducer,
    tubeColor: state.auth.tube.color,
    translate: getTranslation(state.translation.lang),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUploadModal,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDialog)
