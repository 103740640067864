import { setDefaultSettings } from '../../../store/actions/fuse/settings.actions'
import * as _ from 'lodash'
import { signOut } from '../../cognito/organizationApi'
import { Settings, ReduxState } from 'src/store/reducers'
import { Dispatch } from 'redux'

export const SET_USER_DATA = '[USER] SET DATA'
export const SET_PROFILE_DATA = '[USER] SET PROFILE'
export const SET_USER_CONFIGURATION_DATA = '[USER] SET USER_CONFIGURATION'
export const REMOVE_USER_DATA = '[USER] REMOVE DATA'
export const USER_LOGGED_OUT = '[USER] LOGGED OUT'
export const USER_CHANGE_TOOLTIP_SETTINGS = '[USER] CHANGE TOOLTIP SETTINGS'
export const UPDATE_USER_AUTO_PLAY_SETTING = '[USER] UPDATE USER AUTO PLAY'

const isDevStage =
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf(':3000') !== -1 ||
  window.location.href.indexOf('tt-react-dev') !== -1 ||
  window.location.href.indexOf('tt-react-test') !== -1 ||
  window.location.href.indexOf('tt-react-qa') !== -1 ||
  window.location.href.indexOf('.cloudfront.net') !== -1 ||
  window.location.href.indexOf('.dev.trainingtube.com') !== -1

export function setUserData(
  user: {
    role: string
    data: {
      token?: string
      displayName: string
      photoUrl: string
      email: string
      organization?: any
    }
    settings?: Settings
  },
  doNotUpdate?: boolean
) {
  return (dispatch: Dispatch) => {
    // !doNotUpdate && user.role !== 'guest'
    dispatch(setDefaultSettings(user.settings))

    dispatch({
      type: SET_USER_DATA,
      payload: user,
    })
  }
}

export function setUserProfile(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_PROFILE_DATA,
      payload: data,
    })
  }
}

export function setUserConfigurationStatus(isCompleted: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_CONFIGURATION_DATA,
      payload: { isCompleted },
    })
  }
}

export function updateUserAutoPlaySettingData(isAutoPlay) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_USER_AUTO_PLAY_SETTING,
      payload: isAutoPlay,
    })
  }
}

export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user
    const user = _.merge({}, oldUser, { data: { settings } })
    return dispatch(setUserData(user))
  }
}

export function toggleInShortcuts(id) {
  return (dispatch, getState) => {
    let user = getState().auth.user
    let shortcuts = user.data.shortcuts
    shortcuts = shortcuts.includes(id) ? shortcuts.filter(_id => id !== _id) : [...shortcuts, id]
    return dispatch(
      setUserData({
        ...user,
        data: {
          ...user.data,
          shortcuts,
        },
      })
    )
  }
}

export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  }
}

export function changeTooltipSettings(tooltipDisabled: boolean) {
  return (dispatch, getState) =>
    dispatch({
      type: USER_CHANGE_TOOLTIP_SETTINGS,
      payload: {
        tooltipDisabled,
      },
    })
}

export function logoutUser() {
  return (dispatch, getState: () => ReduxState) => {
    const state = getState()

    signOut()

    dispatch({
      type: USER_LOGGED_OUT,
    })
    const encodedFullPath = encodeURIComponent(new URL(window.location.href).pathname + new URL(window.location.href).search);

    const devParam = isDevStage ? '&isDev=true' : ''
    window.location.href =
      'https://www.trainingtube.com/login?redirectTo=' +
      encodedFullPath +
      '&commingFrom=' +
      state.auth.organization.domain +
      devParam +
      '&loggingOut=true'
  }
}
