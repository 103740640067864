import axios from 'axios'

import store from 'src/store'
import { CognitoAPI } from './cognitoApi'
import { addNotificationsData } from 'src/store/actions/notifications.actions'

let userData: {
  cognito: CognitoAPI | null
  orgId: string | null
  tubeId: string | null
  originalOrgId: string | null
} = {
  cognito: null,
  tubeId: null,
  orgId: null,
  originalOrgId: null,
}

// todo: find better way
let mainApi
let mainApiUsers
let mainApiCommand

export const setOrginalOrgId = (originalOrgId: string) => {
  userData.originalOrgId = originalOrgId
}

const fetchResponse = res => {
  const json = res.json()

  if (res.status >= 200 && res.status < 300) {
    return json
  } else {
    if (res.status === 502 && res.type === 'cors') {
      throw { name: 'no organization found' }
    }
    return json.then(Promise.reject.bind(Promise))
  }
}

export function getOrganization(id, mainApiUrl, mainApiUrlCommand, mainApiUsersUrl, isAuthorizedPage) {
  mainApi = mainApiUrl
  mainApiUsers = mainApiUsersUrl
  mainApiCommand = mainApiUrlCommand
  userData.orgId = id

  return fetch(`${mainApi}/organizations/${id}${isAuthorizedPage ? `?fetchShareInfo=${isAuthorizedPage}` : ''}`)
    .then(fetchResponse)
    .then(res => (Object.keys(res).length === 0 ? Promise.reject({ message: 'no organization found' }) : res))
    .then(org => {
      localStorage.setItem('tubeId', org.tubeId)
      return org
    })
}

export async function getOrganizationDomainFromOrgId(orgId, mainApiUrl) {
  mainApi = mainApiUrl

  const apiData = await fetch(`${mainApi}/getDomainFromOrganizationId${`?orgId=${orgId}`}`).then(fetchResponse)

  return apiData?.domain || orgId
}

export async function getNotifications(params?: { isFirstFetch: boolean }) {
  const lang = localStorage.getItem('lang') || 'en-us'

  const data = await axios({
    method: 'GET',
    url: `${mainApi}/notifications?lang=${lang}`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  })

  let newNotifications = []
  if (params && params.isFirstFetch) {
    //  Do nothing
  } else {
    const currentState = store.getState()

    const currentNotificationsIds = currentState.notifications.data.map(item => item.id)
    newNotifications = data.data.data.filter(item => !currentNotificationsIds.includes(item.id))
  }

  addNotificationsData(data.data)(store.dispatch)

  return newNotifications
}

export function getUserFromEmail(email) {
  return fetch(`${mainApi}/userFromEmail?email=${email}&orgId=${userData.originalOrgId}`).then(res => res.json())
}

export function getEmbedData(id, key) {
  return fetch(`${mainApi}/getVideoEmbed?key=${key}&orgId=${id}`).then(res => res.json())
}

export function getUserPermissions() {
  return axios({
    method: 'GET',
    url: `${mainApi}/initData`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  }).then(res => res.data)
}

export function getTube() {
  return axios({
    method: 'GET',
    url: `${mainApi}/tube`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  }).then(res => res.data)
}

export function getLanguageData(lang?: string) {
  return axios({
    method: 'GET',
    url: lang ? `${mainApi}/language?language=${lang}` : `${mainApi}/language`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  }).then(res => res.data)
}

export const initializeCognito = (org: any) => {
  userData.cognito = new CognitoAPI({
    orgId: org.id,
    userPoolId: org.userPoolId,
    clientId: org.userPoolClientId,
  })
  return userData?.cognito?.sessionLogin(org.ssoUrl)
}

export function sessionLogin(ssoUrl: string) {
  return userData?.cognito?.sessionLogin(ssoUrl)
}

export function signIn(username: string, password: string) {
  return userData?.cognito?.signIn(username, password)
}

export function signUp(email: string, password: string, orgId: string) {
  return userData?.cognito?.signUp(email, password, orgId)
}

export function resendConfirmationCode(email: string) {
  return userData?.cognito?.resendConfirmationCode(email)
}

export function confirmRegistration(email: string, password: string, code: string) {
  return userData?.cognito?.confirmRegistration(email, password, code)
}

export function signOut() {
  return userData?.cognito?.signOut()
}

export function forgotPassword(email: string) {
  return userData?.cognito?.forgotPassword(email)
}

export function confirmPassword(email: string, password: string, code: string) {
  return userData?.cognito?.confirmPassword(email, password, code)
}

// todo: move from "orgApi"
export function uploadFile(
  params: {
    file: File
    id: string
  },
  cb = (data: ProgressEvent) => {}
) {
  return axios({
    method: 'post',
    url: `${mainApiCommand}/getS3UploadSignedUrl`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    data: {
      fileExt: params.file.name.split('.').pop(),
    },
  })
    .then(res => res.data)
    .then(data =>
      axios({
        method: 'put',
        url: data.signedUrl,
        headers: {
          'content-type': params.file.type,
        },
        data: params.file,
        onUploadProgress: cb,
      }).then(() => ({
        id: params.id,
        fileName: data.fileName,
        s3Path: data.s3Path,
      }))
    )
}

// todo: should be replaced with dropzone multiple files upload
export async function uploadFiles(
  params: {
    file: File
    id: string
  }[],
  cb = (data: ProgressEvent, id: string) => {}
) {
  let resObj: { [key: string]: string } = {}

  for (const item of params) {
    if (!item.file) continue

    const { data } = await axios({
      method: 'post',
      url: `${mainApiCommand}/getS3UploadSignedUrl`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      data: {
        fileExt: item.file.name.split('.').pop(),
      },
    })
    await axios({
      method: 'put',
      url: data.signedUrl,
      headers: {
        'content-type': item.file.type,
      },
      data: item.file,
      onUploadProgress: data => cb(data, item.id),
    })

    resObj[item.id] = data.fileName || data.s3Path
  }

  return resObj
}

export function createUser(email: string) {
  return axios({
    method: 'POST',
    url: `${mainApiUsers}/createUser`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    data: { email },
  }).then(res => res.data)
}

export function updateUserStatus(params: { email: string; deactivated: boolean }) {
  return axios({
    method: 'POST',
    url: `${mainApiUsers}/updateUserStatus`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    data: { ...params },
  }).then(res => res.data)
}
