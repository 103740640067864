import gql from 'graphql-tag'
import { QueryResult } from 'react-apollo'

export type Channel = {
  id: string
  parent?: string
  pathId?: string
  tubeId?: string
  orgId?: string
  sourceOrgId?: string
  owner?: string
  status?: 'published' | 'private' | 'archived'
  name?: string
  description?: string
  abstract?: string
  channelMandatoryTrainingSetting?: boolean
  isChannelExternalShareEnabled?: boolean
  logo?: string
  icon?: string
  sharedWith: any[]
  externalShareAllowedDomains: string[]
  featuredVideosForRecommendations: string[]
  shared: boolean
  backgroundImage?: string
  featuredVideoRecommendationGenerationOn?: string
  backgroundColor?: string
  isPersonalChannel?: boolean
  firstVideoId?: string
  channelIcon?: string
}

export type GetChannel = QueryResult<{
  channel: Channel
}>

export const getChannelQuery = (channelId: string, getFirstVideoId: boolean) => {
  const variables = {
    channelId,
    getFirstVideoId: getFirstVideoId.toString(),
  }

  return {
    query: gql`
      query($id: String!, $getFirstVideoId: Boolean!) {
        channel(id: $id, getFirstVideoId: $getFirstVideoId)
          @rest(type: "Channel", path: "/channels/:id?getFirstVideoId=:getFirstVideoId", endpoint: "query") {
          id
          parent
          name
          abstract
          sharedWith
          shared
          channelMandatoryTrainingSetting
          isChannelExternalShareEnabled
          externalShareAllowedDomains
          featuredVideosForRecommendations
          featuredVideoRecommendationGenerationOn
          sourceOrgId
          description
          backgroundImage
          logo
          icon
          isPersonalChannel
          pathId
          owner
          firstVideoId
        }
      }
    `,
    variables: { id: variables.channelId, getFirstVideoId: variables.getFirstVideoId },
  }
}

export const GET_CHANNEL_QUERY = gql`
  query($id: String!, $getFirstVideoId: Boolean!) {
    channel(id: $id, getFirstVideoId: $getFirstVideoId)
      @rest(type: "Channel", path: "/channels/:id?getFirstVideoId=:getFirstVideoId", endpoint: "query") {
      id
      parent
      name
      abstract
      sharedWith
      shared
      channelMandatoryTrainingSetting
      isChannelExternalShareEnabled
      externalShareAllowedDomains
      featuredVideosForRecommendations
      featuredVideoRecommendationGenerationOn
      sourceOrgId
      description
      backgroundImage
      logo
      icon
      isPersonalChannel
      pathId
      owner
      firstVideoId
      orgId
    }
  }
`
