import { ApolloClient } from 'apollo-client'
import { RestLink } from 'apollo-link-rest'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { withClientState } from 'apollo-link-state'
import Cookies from 'js-cookie'

import { mainApi } from './config'
import { initialState, localResolvers } from './gql/localData'
import { sessionLogin } from './auth/cognito/organizationApi'

const isDevStage =
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf(':3000') !== -1 ||
  window.location.href.indexOf('tt-react-dev') !== -1 ||
  window.location.href.indexOf('tt-react-test') !== -1 ||
  window.location.href.indexOf('tt-react-qa') !== -1 ||
  window.location.href.indexOf('.cloudfront.net') !== -1 ||
  window.location.href.indexOf('.dev.trainingtube.com') !== -1
const mainApiUrl = mainApi(isDevStage)

const isLocalStage = window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf(':3000') !== -1

const restLink = new RestLink({
  endpoints: {
    query: mainApiUrl.query,
    command: mainApiUrl.command,
    users: mainApiUrl.user,
  },
  typePatcher: {
    Topic: data => {
      if (data.comments != null) {
        data.comments = data.comments.map(comment => ({
          __typename: 'Comment',
          ...comment,
        }))
      }

      return data
    },
    TrackStructure: data => {
      if (data.items) {
        data.items = data.items.map(item => ({
          __typename: 'TrackStructure',
          ...item,
        }))
      }

      return data
    },
    /* … other nested type patchers … */
  },
  headers: {
    'Content-Type': 'application/json',
  },
})

const authRestLink = setContext(() => {
  const token = localStorage.getItem('token')
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }

  return {
    headers,
  }
})

const cache = new InMemoryCache()

const stateLink = withClientState({
  cache,
  resolvers: localResolvers,
  defaults: initialState,
})

const errorLink = (orgId: string, ssoUrl: string) =>
  onError(({ networkError, operation, forward }) => {
    if (
      networkError &&
      networkError['statusCode'] === 401 &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/forgot-password' &&
      window.location.pathname !== '/reset-password' &&
      window.location.pathname !== '/login-lp' &&
      window.location.pathname !== '/auto-login' &&
      window.location.pathname !== '/lp' &&
      window.location.pathname !== '/loginembed' &&
      window.location.pathname !== '/register'
    ) {
      // User access token has expired
      // refresh token through async request
      return new Observable(observer => {
        sessionLogin(ssoUrl)
          .then(() => {
            operation.setContext(() => ({
              headers: {
                Accept: 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }))
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            }

            // Retry last failed request
            forward(operation).subscribe(subscriber)
          })
          .catch(() => {
            // No refresh or client token available, we force user to login
            Cookies.set('prevLocation', window.location.pathname, { expires: 0.1 })
            if (window.location.pathname.startsWith('/embed/') || window.location.pathname.startsWith('/sharing/')) {
              return (window.location.href = '/loginembed?redirect=' + window.location.href)
            }

            if (isLocalStage) {
              return (window.location.href = '/login?redirect=' + window.location.href)
            }

            const devParam = isDevStage ? '&isDev=true' : ''
            const encodedFullPath = encodeURIComponent(new URL(window.location.href).pathname + new URL(window.location.href).search);

            return (window.location.href =
              'https://www.trainingtube.com/login?redirectTo=' +
              encodedFullPath +
              '&commingFrom=' +
              orgId +
              devParam)
          })
      })
    }
  })

const client = (orgId: string, ssoUrl: string) =>
  new ApolloClient({
    link: ApolloLink.from([errorLink(orgId, ssoUrl), authRestLink, restLink, stateLink]),
    cache,
  })

export default client
