import * as React from 'react'
import Cookies from 'js-cookie'
import { matchRoutes } from 'react-router-config'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import isIE from 'is-iexplorer'
import { User, ReduxState } from 'src/store/reducers'
import NotFound from 'src/components/layouts/ResourseNotFound/OrgNotFound'
import BrowserNotSupported from 'src/components/layouts/ResourseNotFound/BrowserNotSupported'

let redirect = false

type Props = {
  user: User
  domain: string
  location: { pathname: string; search: string }
  history: any
  isOrgPresent: boolean
  routes: {
    auth: string[]
    path: string
    component: React.ReactElement<any>
    settings: any
  }
}

const isDevStage =
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf(':3000') !== -1 ||
  window.location.href.indexOf('tt-react-dev') !== -1 ||
  window.location.href.indexOf('tt-react-test') !== -1 ||
  window.location.href.indexOf('tt-react-qa') !== -1 ||
  window.location.href.indexOf('.cloudfront.net') !== -1 ||
  window.location.href.indexOf('.dev.trainingtube.com') !== -1

const isLocalStage = window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf(':3000') !== -1

class Authorization extends React.Component<Props, { loading: boolean }> {
  constructor(props: Props) {
    super(props)

    const { location } = props

    this.state = { loading: true }

    if (
      location.pathname !== '/' &&
      location.pathname !== '/login' &&
      location.pathname !== '/register' &&
      location.pathname !== '/reset-password' &&
      location.pathname !== '/forgot-password' &&
      location.pathname !== '/loginembed' &&
      location.pathname !== '/login-lp' &&
      location.pathname !== '/lp' &&
      location.pathname !== '/auto-login'
    ) {
      Cookies.set('prevLocation', location.pathname, { expires: 0.05 })
    }
  }

  componentDidMount() {
    if (this.props.isOrgPresent) {
      this.checkAuth()
    }
  }

  componentDidUpdate(prevProps: Props) {
    /**
     * If route is changed
     * Update auths
     */
    if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
      if (
        prevProps.location.pathname !== '/' &&
        prevProps.location.pathname !== '/login' &&
        prevProps.location.pathname !== '/register' &&
        prevProps.location.pathname !== '/reset-password' &&
        prevProps.location.pathname !== '/forgot-password' &&
        prevProps.location.pathname !== '/loginembed' &&
        prevProps.location.pathname !== '/auto-login' &&
        prevProps.location.pathname !== '/lp' &&
        prevProps.location.pathname !== '/login-lp'
      ) {
        Cookies.set('prevLocation', prevProps.location.pathname, { expires: 0.05 })
      }
      if (this.props.isOrgPresent) {
        this.checkAuth()
      }
    }
  }

  checkAuth() {
    const matched = matchRoutes(this.props.routes, this.props.location.pathname)[0]

    if (matched && matched.route.auth && matched.route.auth.length > 0) {
      if (!matched.route.auth.includes(this.props.user.role)) {
        const prevLocation = Cookies.get('prevLocation')
        redirect = true
        if (this.props.user.role === 'guest') {
          const redirectUrl = prevLocation || this.props.location.pathname
          Cookies.set('prevLocation', redirectUrl, { expires: 0.1 })

          if (isLocalStage) {
            return (window.location.href = '/login?redirect=' + window.location.pathname)
          }

          const encodedFullPath = encodeURIComponent(
            new URL(window.location.href).pathname + new URL(window.location.href).search
          )

          console.log({ encodedFullPath })

          const devParam = isDevStage ? '&isDev=true' : ''
          window.location.href =
            'https://www.trainingtube.com/login?redirectTo=' +
            encodedFullPath +
            '&commingFrom=' +
            this.props.domain +
            devParam
        } else {
          const redirect = getParameterByName('redirect')
          const redirectUrl = redirect || prevLocation

          setImmediate(() => {
            this.setState({
              loading: false,
            })
          })

          this.props.history.push({
            pathname: redirectUrl || '/',
          })
        }
      }
    }

    this.setState({
      loading: false,
    })
  }

  shouldComponentUpdate() {
    if (redirect) {
      redirect = false
      return false
    } else {
      return true
    }
  }

  render() {
    const { children, isOrgPresent } = this.props

    if (isIE) {
      return <BrowserNotSupported />
    }

    if (!isOrgPresent) {
      return <NotFound />
    }

    if (this.state.loading) {
      return (
        <div id="fuse-splash-screen">
          <div className="center">
            <div className="logo">
              <img width="128" src="assets/images/logos/icon-logo.png" alt="logo" />
            </div>

            <div className="spinner-wrapper">
              <h6 className="text-center title">
                Have you tried to use <strong>Training Tube </strong> to ...
              </h6>
              <p className="text-center semi-title">Documenting specification for new software features ?</p>
              <p className="text-center semi-title">It's 10X faster than written document</p>
            </div>

            <div className="spinner-wrapper">
              <div className="spinner">
                <div className="inner">
                  <div className="gap" />
                  <div className="left">
                    <div className="half-circle" />
                  </div>
                  <div className="right">
                    <div className="half-circle" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <React.Fragment>{children}</React.Fragment>
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps({ auth }: ReduxState) {
  return {
    user: auth.user,
    domain: auth.organization.domain,
  }
}

function getParameterByName(name: string, url?: string) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authorization))
